:root {
  --accent-color: #0066cc;
  --background-color: #ffffff;
  --text-color: #000000;
  --light-gray: #f5f5f5;
  --medium-gray: #e0e0e0;
  --dark-gray: #666666;
}

.App {
  text-align: left;
  margin-bottom: 2em;
}

h1,h2,h3,h4,p {
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

div.post-tile {
  background-color: var(--light-gray);
  padding: 16px;
  border: 1px solid var(--medium-gray);
}

div.posts-list {
  width: 60vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 24px;
}

a.post-tile {
  color: var(--text-color);
}

h1.post-tile {
  font-size: large;
  font-weight: bold;
}

h1.post-tile {
  background-color: var(--accent-color-2);
  -webkit-transition: background-color 1s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

h2.article-content {
  font-size: large;
  font-weight: bolder;
  color: var(--accent-color-4);
}

h3.post-tile {
  font-size: small;
  font-weight: normal;
}

h3.article-content {
  font-size: medium;
  font-weight: bold;
}

div.article-content {
  word-wrap: break-word;
  width: 40vw;
  margin: auto;
}

div.center {
  text-align: center;
}

blockquote.article-content {
  color: var(--quote-font-color);
  border-left: 5px solid var(--quote-border-color);
  padding: 8px;
  margin: 1vw;
  font-size: medium;
}

.article-content {
  font-weight: 300;
  user-select: text;
  pointer-events: auto;
}

b.article-content {
  font-weight: 400;
}

p.article-content {
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-size: medium;
  width: auto;
}

div.topics {
  padding-top: 4px;
}

a.article-content.topic {
  font-size: small;
  margin-right: 8px;
  color: var(--accent-color);
  text-decoration: underline;
}

ul.article-content {
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: medium;
}

ol.article-content {
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: medium;
  margin: 0px;
}

li.article-content {
  padding-top: 4px;
}

ul.reference-box {
  padding-left: 0;
  margin-top: 16px;
  margin-bottom: 0;
  list-style-type: none;
}

li.reference-link {
  margin-bottom: 8px;
}

h1.article-content, h2.article-content {
  margin-bottom: 1vh;
  margin-top: 1vh;
}

p.footnote {
  font-size: small;
}

header.article-content {
  font-size: x-large;
  background-color: var(--background-color);
  padding: 32px 0;
  margin-top: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid var(--medium-gray);
}

p.post-tile {
  font-size: small;
}

a {
  text-decoration: none;
}

a.reference-link:visited, a.reference-link {
  color: var(--accent-color);
}

a.inline-ref {
  color: var(--accent-color);
  text-decoration: underline;
}

div.reference-box {
  font-size: small;
  background-color: var(--light-gray);
  padding: 32px;
  margin-top: 48px;
  border-top: 1px solid var(--medium-gray);
}

td.article-content, th.article-content {
  border: 1px solid #ddd;
  padding: 8px;
}

th.article-content {
  color: var(--accent-color-4);
  font-size: medium;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

svg.logo {
  width: 60px;
  height: auto;
}

header.App-header {
  background-color: var(--background-color);
  border-bottom: 1px solid var(--medium-gray);
  min-height: 8vh;
  padding-left: 16px;
  padding-right: 16px;
}

div.App-title-header {
  flex-direction: row;
  align-items: center;
  justify-content: left;
  display: flex;
}

p.App-header {
  font-size: large;
  padding: 0px 12px;
  color: var(--text-color);
}

p.App-subheader {
  padding: 4px 0px;
  color: var(--dark-gray);
  font-size: small;
}

div.about {
  width: 60vw;
  margin: auto;
  padding-top: 32px;
}

div.about p {
  margin-bottom: 24px;
  color: var(--text-color);
  font-size: medium;
}

div.about a {
  color: var(--accent-color);
  text-decoration: underline;
}

.App-nav {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

a.menu {
  margin-right: 1em;
  font-size: small;
  color: var(--light-font-color);
  text-decoration: none;
}

form.subscribe {
  margin-top: 1em;
}

input.subscribe, button.subscribe {
  font-size: small;
  border-radius: 4px;
  border: 1px solid var(--accent-color-2);
  color: var(--accent-color-0);
  background-color: var(--light-font-color);
}

input.subscribe {
  margin-right: 1em;
}

button.subscribe {
  background-color: var(--accent-color-0);
  color: var(--light-font-color);
  cursor: pointer;
}

img.article-image {
  width: 100%;
  height: auto;
}

body {
  user-select: text;
}

@media only screen and (max-width: 768px) {
  div.article-content {
    width: 90vw;
    margin: auto;
  }

  header.article-content {
    font-size: large;
  }

  div.about {
    width: 90vw;
    margin: auto;
    padding-top: 24px;
  }

  div.posts-list {
    width: 90vw;
  }
}
