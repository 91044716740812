@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

body, form, input, button {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
}

code {
  font-family: 'Cutive Mono', 'Source Code Pro', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
